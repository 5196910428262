































































































































































































import Vue from "vue";
import HeroText from "@/components/modules/HeroText.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { getRouteName } from "@/routeConfig";

export default Vue.extend({
  components: {
    HeroText,
  },
  mounted() {
    const recaptcha = this.$recaptchaInstance;
    if (!this.isLoggedIn) {
      recaptcha.showBadge();
    }
  },
  data() {
    return {
      options: [
        {
          title: this.$t("contactForm.additionalService"),
          id: "additionalService",
        },
        {
          title: this.$t("contactForm.appointment"),
          id: "appointment",
        },
        {
          title: this.$t("contactForm.change"),
          id: "change",
        },
        {
          title: this.$t("contactForm.orderStatus"),
          id: "orderStatus",
        },
        {
          title: this.$t("contactForm.changeOfAddress"),
          id: "changeOfAddress",
        },
        {
          title: this.$t("contactForm.changeOfContactDetails"),
          id: "changeOfContactDetails",
        },
        {
          title: this.$t("contactForm.deliveryRestrictions"),
          id: "deliveryRestrictions",
        },
        {
          title: this.$t("contactForm.documentRequest"),
          id: "documentRequest",
        },
        {
          title: this.$t("contactForm.orderCancellation"),
          id: "orderCancellation",
        },
        {
          title: this.$t("contactForm.complaint"),
          id: "complaint",
        },
        {
          title: this.$t("contactForm.orderQuestions"),
          id: "orderQuestions",
        },
        {
          title: this.$t("contactForm.privacyQuestions"),
          id: "privacyQuestions",
        },
        {
          title: this.$t("contactForm.other"),
          id: "other",
        }
      ],
      topic: {
        title: "",
        id: "",
      },
      isPreFilled: {
        name: false,
        orderNumber: false,
        postcode: false,
        emailAddress: false,
        phone: false,
      },
      name: "",
      orderNumber: "",
      postcode: "",
      emailAddress: "",
      phone: "",
      message: "",
      topicValidationMessage: "",
      topicValidationErrors: "",
      nameValidationMessage: "",
      nameValidationErrors: "",
      orderNumberValidationMessage: "",
      orderNumberValidationErrors: "",
      postcodeValidationMessage: "",
      postcodeValidationErrors: "",
      emailAddressValidationMessage: "",
      emailAddressValidationErrors: "",
      phoneValidationMessage: "",
      phoneValidationErrors: "",
      messageValidationMessage: "",
      messageValidationErrors: "",
      checkboxValidationMessage: "",
      checkboxChecked: false,
      captchaValidationFailed: false,
      isLoading: false
    };
  },
  methods: {
    getLinkToPrivacy() {
      return { name: getRouteName("privacy") };
    },
    isOrderAvailable() {
      return this.isLoggedIn && this.getExternalOrderNumber;
    },
    isCustomerAvailable() {
      return this.isLoggedIn && this.getAddress;
    },
    validateForm() {
      let validForm = true;
      this.captchaValidationFailed = false;

      for (const element in this.$refs) {
        if (!this.validateElement(element)) {
          validForm = false;
        }
      }

      if (!this.validateCheckbox()) {
        validForm = false;
      }

      return validForm;
    },
    validateCheckbox() {
      // custom logic for checkbox because of google chrome focus bug
      if (!this.checkboxChecked) {
        this.checkboxValidationMessage = this.$t(
          "validationErrors.dataPrivacy"
        ) as string;
        return false;
      }
      return true;
    },
    validateElement(elementName: string) {
      const formElement = this.$refs[elementName] as HTMLFormElement;
      const validity = formElement.validity;

      let errorMsg = "";

      if (validity.valueMissing) {
        if (elementName === "topic") {
          errorMsg = "validationErrors.invalidRequiredDropdown";
        } else {
          errorMsg = "validationErrors.invalidRequiredText";
        }
      } else if (validity.patternMismatch) {
        errorMsg = "validationErrors.invalidPattern";
      } else if (validity.typeMismatch && formElement.type === "email") {
        errorMsg = "validationErrors.invalidEmail";
      }

      const validationOk = errorMsg === "";
      const localizedMessage = validationOk
        ? errorMsg
        : (this.$t(errorMsg) as string);

      this.setValidationStatusForElement(
        elementName,
        validationOk,
        localizedMessage
      );

      return validationOk;
    },
    setValidationStatusForElement(
      elementName: string,
      validationOk: boolean,
      localizedMessage: string
    ) {
      const self = this as any;
      const statusFlagIndex = `${elementName}ValidationErrors`;
      const validationMessageIndex = `${elementName}ValidationMessage`;

      self[statusFlagIndex] = validationOk ? "ok" : "error";
      self[validationMessageIndex] = localizedMessage;
    },
    async send() {
      const validForm = this.validateForm();

      if (!validForm) return;
      this.isLoading = true;

      const request = {
        topicKey: this.topic.id,
        topicTitle: this.topic.title,
        name: this.name,
        orderNumber: this.orderNumber,
        postcode: this.postcode,
        phone: this.phone,
        emailAddress: this.emailAddress,
        message: this.message,
      } as IContactRequest;


      this.sendContactEmail(request)
        .then(() => {
          this.$navigateTo("contactSuccess");
        })
        .catch(() => {
          this.captchaValidationFailed = true;
        })
        .finally(() => this.isLoading = false);
    },
    ...mapActions(["sendContactEmail"]),
  },
  computed: {
    ...mapState(["isLoggedIn"]),
    ...mapGetters(["getExternalOrderNumber", "getAddress"]),
  },
  created() {
    if (this.isOrderAvailable()) {
      this.orderNumber = this.getExternalOrderNumber;
      this.isPreFilled.orderNumber = Boolean(this.orderNumber);
    }

    if (!this.isCustomerAvailable()) {
      return;
    }

    const address = this.getAddress;

    let customerName = [address.title, address.firstName, address.surName]
      .filter(Boolean)
      .join(" ");
    if (!customerName.trim()) {
      customerName = "";
    }

    let telephones = [
      address.telephone1,
      address.telephone2,
      address.telephone3,
    ]
      .filter(Boolean)
      .join(", ");
    if (!telephones.replace(",", "").trim()) {
      telephones = "";
    }
    if (telephones.trimEnd().endsWith(",")) {
      telephones = telephones.substr(0, telephones.lastIndexOf(","));
    }

    this.name = customerName;
    this.postcode = address.zipCode;
    this.emailAddress = address.email;
    this.phone = telephones;

    this.isPreFilled.name = Boolean(this.name);
    this.isPreFilled.postcode = Boolean(this.postcode);
    this.isPreFilled.emailAddress = Boolean(this.emailAddress);
    this.isPreFilled.phone = Boolean(this.phone);
  },
  beforeRouteLeave(to, from, next) {
    const recaptcha = this.$recaptchaInstance;
    recaptcha.hideBadge();
    next();
  },
});
